@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.menu-text {
  transition: all 3s !important;
  font-size: 14px !important;
}

:root {
  --sidebar-bg-color: #28282b;
  --sidebar-bg-color-rgb: 40, 40, 43;
  --btn-bg-primary: #28282b;
  --btn-bg-primary-rgb: rgb(40, 40, 43);
  --bg-btn-hover: #000000;
  --bg-btn-hover-rgb: rgb(0, 0, 0);
  --primary-bg-color: #f0f0f0;
  --primary-bg-color-rgb: 245, 245, 245;
  --secondary-bg-color: #fff;
  --secondary-bg-color-rgb: rgb(255, 255, 255);
  --primary-text-color: #28282b;
  --primary-text-color-rgb: rgb(40, 40, 43);
  --border-color: #e5e5e5;
  --border-color-rgb: 229, 229, 229;
  --focus-border-color: #a3a3a6;
  --focus-border-color-rgb: rgb(163, 163, 166);
  --focus-tab-color: #ececec;
  --focus-tab-color-rgb: rgb(236, 236, 236);
  --table-text-color: #666b78;
  --table-text-color-rgb: rgb(102, 107, 120);
  --tawk-header-background-color: var(--sidebar-bg-color);
  --tawk-header-background-color-rgb: var(var(--sidebar-bg-color-rgb));
}

.dark-mode {
  --sidebar-bg-color: #111;
  --sidebar-bg-color-rgb: rgb(17, 17, 17);
  --primary-bg-color: #222;
  --primary-bg-color-rgb: rgb(34, 34, 34);
  --secondary-bg-color: #333333;
  --secondary-bg-color-rgb: rgb(51, 51, 51);
  --primary-text-color: #ccc;
  --primary-text-color-rgb: rgb(204, 204, 204);
  --border-color: #444;
  --border-color-rgb: rgb(68, 68, 68);
  --table-text-color: #a0a0a0;
  --table-text-color-rgb: rgb(160, 160, 160);
  --focus-border-color: #555;
  --focus-border-color-rgb: rgb(85, 85, 85);
  --focus-tab-color: #444;
  --focus-tab-color-rgb: rgb(68, 68, 68);
}

.close.sidebar .menu-text {
  display: none;
  transition: all 3s !important;
}

.close-btn {
  width: 25px;
  height: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  color: var(--table-text-color);
  transition: all 0.3s;
  border-radius: 4px;
  border: none;
  outline: none;
}

.close-btn:hover {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color);
}

/* .min-w-\[240px\] .sidebar .menu-text {
    display: block;
} */

.react-datepicker__input-container input {
  background-color: var(--secondary-bg-color) !important;
  color: var(--primary-text-color);
  border-color: var(--border-color) !important;
}

.menu-item,
.menu-item-close {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  z-index: 1;
  font-weight: 500 !important;
  overflow: hidden;
  transition: all 0.3s;
  font-size: 14px !important;
  padding: 9px !important;
  background-color: transparent;
}

.menu-arrow:hover {
  background-color: rgba(250, 249, 246, 10%);
  /* opacity: 10%; */
}

.tawk-button {
  background-color: var(--sidebar-bg-color) !important;
}

.status-bedge {
  padding: 4px 12px !important;
  border: 1px solid;
  position: relative;
  display: inline-block;
  border-radius: 30px;
  font-weight: 500;
  font-size: 12px;
  text-transform: capitalize;
}

.menu-item-close {
  color: #c9c8c8 !important;
}

.dark-mode .menu-item-close {
  color: var(--table-text-color) !important;
}

.menu-item-close:hover {
  color: white !important;
}

.out-of-stock {
  background-color: #fee2e2;
  color: #ef4444;
}

.dark-mode .out-of-stock {
  color: #fee2e2;
  background-color: rgb(211, 66, 66);
}

.in-stock {
  background-color: #dcfce7;
  color: #16a34a;
}

.dark-mode .in-stock {
  background-color: #16a34a;
  color: #dcfce7;
}

.yellow {
  background-color: #fef9c3;
  color: #ca8a04;
}

.dark-mode .yellow {
  color: #222222;
  background-color: #ecb745;
}

.menu-item span,
.menu-item-close span {
  font-size: 14px;
  font-weight: 500 !important;
  /* letter-spacing: 0.5s !important; */
}

.menu-item::after,
.menu-item-close::after {
  content: '';
  width: 100% !important;
  height: 100% !important;
  background-color: #faf9f6;
  opacity: 10% !important;
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  transition: all 0.3s;
  border-radius: 6px 5px 5px 6px !important;
}

.fixed-sidebar .menu-item::after,
.fixed-sidebar .menu-item-close::after {
  background-color: #faf9f6;
  opacity: 10%;
}

.dark-mode .menu-item::after,
.dark-mode .menu-item-close::after,
.dark-mode .fixed-sidebar .menu-item::after,
.dark-mode .fixed-sidebar .menu-item-close::after {
  background-color: var(--primary-bg-color) !important;
}

/* .menu-item::before,
.menu-item-close::before {
    content: '';
    width: 2px;
    height: 100%;
    background-color: white;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.3s;
} */
.fixed-sidebar .menu-item-close::after,
.fixed-sidebar .menu-item-close::before {
  color: #faf9f6;
}

.dark-mode .fixed-sidebar .menu-item-close::after,
.dark-mode .fixed-sidebar .menu-item-close::before,
.dark-mode .menu-item-close::after,
.dark-mode .menu-item-close::before {
  color: var(--table-text-color) !important;
}

.menu-item-close::after {
  opacity: 0;
  color: #faf9f6 !important;
  background-color: transparent !important;
}

.menu-item-close::before {
  top: 100%;
  color: #faf9f6 !important;
}

select {
  appearance: none;
  padding: 8px 30px 8px 12px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  border: 1px solid var(--border-color);
  border-color: var(--border-color) !important;
  cursor: pointer !important;
  outline: none !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: var(--secondary-bg-color) !important;
}

.live-editor select {
  appearance: none;
}

.filterType select {
  cursor: auto;
}

.filter {
  min-width: 300px !important;
}

select:focus {
  border: 1px solid #6b7280 !important;
}

.adminseacrh select {
  border: none;
  padding: 8px 10px;
}

.adminseacrh select:focus {
  border: none;
}

input[type='text'],
input[type='number'],
input[type='Number'],
input[type='email'],
input[type='password'],
textarea {
  padding: 8px 12px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  font-weight: 400 !important;
  box-shadow: none !important;
  outline: none !important;
  /* background: transparent !important */
  /* background-color: var(--secondary-bg-color) ; */
}

.editor-title input[type='text'],
input[type='number'],
input[type='Number'],
input[type='email'],
input[type='password'],
textarea {
  font-size: 12px !important;
}

.editor-title input[type='text'],
input[type='number'],
input[type='Number'],
input[type='email'],
input[type='password'],
textarea {
  font-size: 12px !important;
}

.bg input {
  /* padding: 12px 12px !important; */
  font-size: 15px !important;
}

.bg button:not(.MuiButtonBase-root) {
  padding: 10px 20px !important;
}

.bg .autocomplete .MuiFormControl-root .MuiInputBase-root {
  padding: 13px 35px 13px 12px !important;
}

.MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: var(--btn-bg-primary) !important;
}

/* input[type=checkbox] {
    position: relative;
    cursor: pointer;
} */

.MuiButtonBase-root.MuiCheckbox-root input[type='checkbox'] {
  position: absolute;
}

.MuiButtonBase-root.MuiCheckbox-root .MuiSvgIcon-root {
  width: 19px !important;
  height: 19px !important;
  /* fill: currentColor; */
  /* color: white; */
  font-weight: 400;
}

.dark-mode .MuiButtonBase-root.MuiCheckbox-root .MuiSvgIcon-root {
  fill: var(--table-text-color);
}

.dark-mode .MuiButtonBase-root.MuiCheckbox-root.MuiCheckbox-indeterminate .MuiSvgIcon-root,
.dark-mode .MuiButtonBase-root.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root {
  fill: #1976d2 !important;
  color: white !important;
}

.MuiButtonBase-root.MuiCheckbox-root:hover {
  background-color: transparent !important;
}

/* input[type=checkbox]:before {
    content: "";
    display: block;
    position: absolute;
    width: 16px;
    height: 16px;
    top: 0;
    left: 0;
    border: 1px solid var(--table-text-color);
    border-radius: 3px;
    padding: 1px;
} */

.dark-mode input[type='checkbox']:before {
  background-color: var(--secondary-bg-color);
}

[type='checkbox']:checked:after:not(.dark-mode) {
  content: '';
  display: block;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: 2px;
  left: 6px;
}

.dark-mode input[type='text'],
.dark-mode input[type='file'],
.dark-mode input[type='number'],
.dark-mode input[type='Number'],
.dark-mode input[type='email'],
.dark-mode input[type='password'],
.dark-mode textarea {
  background-color: transparent !important;
  color: var(--table-text-color);
}

.file-tag-hover {
  border: 2px dashed rgba(var(--sidebar-bg-color-rgb), 30%) !important;
  background-color: rgba(var(--primary-bg-color-rgb), 70%) !important;
  transition: all 0.3s !important;
}
.dark-mode .file-tag-hover {
  border: 2px dashed var(--border-color) !important;
}

.file-tag-hover:hover {
  background-color: rgba(var(--primary-bg-color-rgb), 90%) !important;
  border-color: rgba(var(--sidebar-bg-color-rgb), 60%) !important;
}

.dark-mode .file-tag-hover:hover {
  background-color: var(--primary-bg-color);
}

.dark-mode select {
  background-color: var(--secondary-bg-color);
  color: var(--table-text-color);
}

.ql-container.ql-snow,
.ql-toolbar.ql-snow {
  border-color: var(--border-color) !important;
}

.ql-snow .ql-stroke,
.ql-snow .ql-picker,
.ql-snow .ql-fill,
.ql-editor.ql-blank::before,
.ql-editor {
  stroke: var(--table-text-color) !important;
  color: var(--table-text-color) !important;
  /* background-color: var(--secondary-bg-color) !important; */
}

.MuiRating-icon.MuiRating-iconEmpty {
  color: var(--table-text-color) !important;
}

.apexcharts-text.apexcharts-xaxis-label,
.apexcharts-text.apexcharts-yaxis-label {
  fill: var(--table-text-color) !important;
}

input[type='text']:focus,
input[type='number']:focus,
input[type='Number']:focus,
input[type='email']:focus,
input[type='password']:focus,
textarea:focus {
  border-color: #454545 !important;
}

input[type='date'] {
  padding: 8px 12px !important;
  font-size: 14px !important;
  line-height: 20px !important;
  border-radius: 4px !important;
  font-weight: 400 !important;
  cursor: pointer !important;
}

.select-arrow {
  position: absolute;
  top: 50%;
  right: 14px;
  z-index: 5 !important;
  transform: translateY(-50%);
  border-top: 5px solid var(--table-text-color);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}

summary {
  cursor: pointer !important;
}

.data-form {
  border-radius: 4px;
  background-color: var(--secondary-bg-color) !important;
  border: 1px solid var(--border-color) !important;
  padding: 30px;
  margin: 0 auto;
}

form label {
  display: block;
  color: var(--table-text-color);
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 4px;
  text-transform: capitalize !important;
}

.MuiRating-root label {
  font-size: 25px !important;
  color: inherit !important;
}

.rdt_TableHeader {
  border-bottom: 1px solid var(--border-color) !important;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root {
  padding: 9px 35px 9px 12px !important;
  /* background-color: var(--secondary-bg-color); */
}

.MuiPaper-root.MuiPaper-elevation,
.MuiAutocomplete-noOptions,
.MuiPaper-root.MuiPaper-elevation > div {
  background-color: var(--secondary-bg-color) !important;
  color: var(--table-text-color) !important;
}

@media (max-width: 767px) {
    .MuiPaper-root.MuiPaper-elevation > div {
        background-color: var(--sidebar-bg-color) !important;
        color: var(--table-text-color) !important;
      }
}

.live-editor-responsive .MuiPaper-root.MuiPaper-elevation {
  max-height: 90% !important;
}

.MuiSvgIcon-root {
  fill: var(--table-text-color) !important;
}

.dark-mode .autocomplete .MuiFormControl-root .MuiInputBase-root {
  background-color: transparent !important;
  color: var(--table-text-color);
}

.autocomplete .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline,
.productLaunch .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-color: var(--border-color) !important;
  border-radius: 4px;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  /* border-color: black !important; */
  border: 1px solid #454545 !important;
  outline: none !important;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root .MuiInputBase-input {
  padding: 0 !important;
  line-height: 20px !important;
  font-size: 14px !important;
  color: var(--table-text-color);
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  overflow: hidden !important;
}

.autocomplete .MuiFormControl-root .MuiAutocomplete-selected-value {
  text-overflow: ellipsis !important;
}

.autocomplete .MuiFormControl-root .MuiInputBase-root input::placeholder {
  color: var(--table-text-color) !important;
}

.productLaunch .MuiStack-root {
  padding: 0;
  overflow: hidden;
}

.contryCode .MuiFormControl-root .MuiInputBase-root .MuiOutlinedInput-notchedOutline {
  border-radius: 4px 0 0 4px !important;
  border-right: none;
}

.contryCode input {
  border-radius: 0px 4px 4px 0 !important;
  /* border-left: none; */
}

.back-arrow {
  width: 45px;
  height: 45px;
  background-color: transparent;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  border-radius: 100%;
}

.back-arrow:hover {
  background-color: var(--secondary-bg-color);
}

.headerMenu .headerMenuBtn {
  opacity: 0;
  transition: all 0.3s;
}

.headerMenu {
  transition: all 0.3s;
}

.headerMenu:hover .headerMenuBtn,
.headerMenu:hover {
  opacity: 1;
  color: var(--primary-text-color) !important;
  background-color: var(--primary-bg-color) !important;
}

.menu-hover,
.acc-hover {
  position: relative;
}

.menu-hover .menu-dropdown,
.acc-hover .acc-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  opacity: 0;
  height: auto;
  visibility: hidden;
  z-index: 20;
  transition: all 0.2s;
}

.menu-hover .menu-dropdown {
  left: 0 !important;
}

.header:hover .acc-dropdown {
  opacity: 0;
  visibility: hidden;
}

.menu-hover:hover .menu-dropdown,
.acc-hover:hover .acc-dropdown {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.slider-container {
  overflow: hidden;
  width: 100%;
  /* Adjust width as needed */
}

.slider-track,
.slider-wrapper {
  display: flex;
  position: relative;
  transition: transform 0.5s ease-in-out;
}

.slider-slide {
  /* flex: 0 0 auto; */
  width: 100%;
  text-align: center;
  padding: 10px;
}

/* .mySlides {display: none} */
.slideshow-container {
  max-width: 1000px;
  position: relative;
  margin: auto;
}

.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: var(--border-color);
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* .active, .dot:hover {
    background-color: #717171;
  } */

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

.blog-slider .slick-slide {
  display: grid !important;
}

.slick-slider .slick-list .slick-track {
  display: flex !important;
  flex-wrap: nowrap !important;
}

@keyframes fade {
  from {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}

.swiper-slide {
  width: auto !important;
}

.how-it-works {
  padding: 4px 6px;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  white-space: nowrap;
  font-size: 12px;
  line-height: 14px;
  font-weight: 400;
  color: var(--table-text-color);
  margin-bottom: 2px;
  opacity: 1;
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
  background-color: var(--secondary-bg-color);
  transition: all 0.3s;
}

.how-it-works:hover {
  color: var(--primary-text-color);
}

table {
  border-color: var(--border-color);
}

table thead tr {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: rgba(var(--primary-bg-color-rgb), 70%) !important;
  color: var(--btn-bg-primary) !important;
  font-weight: 600;
  text-transform: uppercase;
  min-height: 40px !important;
  font-size: 12px;
  border: none !important;
}
.dark-mode table thead tr {
  color: var(--table-text-color) !important;
}
table tbody tr {
  height: 55px !important;
  border-bottom: 1px solid rgba(var(--border-color-rgb), 70%) !important;
  background-color: var(--secondary-bg-color) !important;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: var(--table-text-color) !important;
}
.dark-mode table tbody tr {
  border-bottom: 1px solid var(--border-color) !important;
}
.module-table table tbody tr {
  height: auto !important;
}

table tbody tr:hover {
  background-color: rgba(var(--primary-bg-color-rgb), 70%) !important;
  outline: none !important;
}
.dark-mode table tbody tr:hover {
  background-color: var(--btn-bg-primary) !important;
}

.thead-image {
  min-width: 80px;
  max-width: 80px;
}

.selected-row,
.selected-row:hover,
.dMnwYK:nth-of-type(n) {
  background-color: rgba(var(--primary-bg-color-rgb), 70%) !important;
}

.dark-mode .selected-row,
.dark-mode .selected-row:hover,
.dark-mode .dMnwYK:nth-of-type(n),
.dark-mode .dMnwYK:nth-of-type(n):hover {
  background-color: var(--sidebar-bg-color) !important;
  border-color: var(--border-color) !important;
}

.dMnwYK:hover {
  outline: none !important;
}

.table-image img {
  max-height: 50px;
  display: inline-block;
  margin: 0 auto;
  object-fit: contain;
}

.drag-btn {
  opacity: 0;
}

.table tbody tr:hover .drag-btn {
  opacity: 1 !important;
}

.table-head-checkbox,
.table-body-checkbox {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  width: 50px;
}

.category-hover img {
  transition: all 0.3s;
}

.category-hover:hover img {
  transform: scale(1.1);
}

.table-body-checkbox {
  height: 55px;
}

/* .add-variant-table,
.add-discount-table,
.customer-table,
.blog-table,
.category-table,
.product-table,
.sticky-table {
    border-bottom: 1px solid rgba(var(--border-color-rgb),70%) !important;
} */

.sticky-table table thead tr td:nth-child(3),
.sticky-table table tbody tr td:nth-child(3),
.customer-table table thead tr td:nth-child(3),
.customer-table table tbody tr td:nth-child(3),
/* .category-table table thead tr td:nth-child(2),
.category-table table tbody tr td:nth-child(2), */
.add-variant-table table thead tr td:first-child,
.add-variant-table table tbody tr td:first-child {
  position: sticky !important;
  left: 0 !important;
  /* width: 200px !important; */
  /* background-color: inherit !important; */
  z-index: 5 !important;
}

.sticky-table table thead tr th:last-child,
.sticky-table table tbody tr td:last-child,
.product-table table thead tr th:last-child,
.product-table table tbody tr td:last-child,
.category-table table thead tr td:last-child,
.category-table table tbody tr td:last-child,
.blog-table table thead tr th:last-child,
.blog-table table tbody tr td:last-child,
.customer-table table thead tr th:nth-last-child(2),
.customer-table table tbody tr td:nth-last-child(2),
.add-discount-table table thead tr td:last-child,
.add-discount-table table tbody tr td:last-child {
  position: sticky !important;
  right: 0 !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  /* width: 200px; */
  background-color: inherit;
  z-index: 5;
}

.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 700px !important;
  margin: 10px !important;
}

.react-datepicker__time-container {
  width: auto !important;
}

.schdule-time .react-datepicker-popper {
  position: relative !important;
  transform: translate(0, 0) !important;
}

.bulk-btn {
  color: var(--primary-text-color);
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--border-color);
  padding: 6px 15px;
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 13px;
  font-weight: 500;
}

.filter-btn {
  background-color: var(--secondary-bg-color);
  border: 1px solid var(--border-color);
  width: 40px;
  height: 40px;
  /* padding: 6px 15px; */
  border-radius: 4px;
  transition: all 0.2s;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.bulk-btn:hover,
.filter-btn:hover {
  background-color: var(--focus-tab-color) !important;
  border-color: #a6a6ab !important;
}
.dark-mode .bulk-btn:hover,
.dark-mode .filter-btn:hover {
  border-color: var(--btn-bg-primary) !important;
}
.filter-btn svg {
  width: 18px;
  height: 18px;
}

.slug-edit {
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
}

.gocYik {
  background-color: rgba(var(--primary-bg-color-rgb), 70%) !important;
  color: var(--btn-bg-primary);
  font-weight: 600;
  text-transform: uppercase;
  min-height: 40px !important;
  font-size: 12px;
  border: none !important;
  /* border-bottom: 1px solid var(--border-color) !important; */
}
.dark-mode .gocYik {
  color: var(--table-text-color) !important;
}

.admin-search .MuiPaper-root.MuiPaper-elevation {
  left: 50% !important;
  top: 5% !important;
  transform: translateX(-50%) !important;
}

details {
  padding: 0px !important;
  background-color: var(--secondary-bg-color) !important;
}

details summary {
  padding: 25px !important;
  position: relative;
}

.theme-setting details summary {
  padding: 15px !important;
}

:not(.editor-details)details[open] summary::after {
  content: '';
  top: 100%;
  left: 0;
  position: absolute;
  width: 95%;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  background: var(--border-color);
  margin-bottom: 25px;
}

details > div {
  padding: 0px 25px 0px 25px !important;
}

.editor-details details > div {
  padding: 0 !important;
}

.editor-heading {
  font-size: 14px !important;
}

.editor-title {
  font-size: 12px !important;
}

.hover-icon path {
  stroke: #555555;
}

.select-container {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.hover-icon:hover path {
  stroke: #ffffff;
}

.editor-details details > div:last-child {
  padding: 0 !important;
}

.editor-details details > div {
  padding: 0 !important;
}

.editor-heading {
  font-size: 14px !important;
}

.editor-title {
  font-size: 12px !important;
}

.hover-icon path {
  stroke: #555555;
}

.select-container {
  background-color: #f6f6f6 !important;
  border: none !important;
}

.hover-icon:hover path {
  stroke: #ffffff;
}

.editor-details details > div:last-child {
  padding: 0 !important;
}

.theme-setting details summary {
  appearance: none !important;
}

.theme-setting details > div {
  padding: 0px 15px 0px 15px !important;
}

details hr {
  display: none !important;
}

details > div:last-child {
  padding-bottom: 25px !important;
}

.theme-setting details > div:last-child {
  padding-bottom: 15px !important;
}

details > div:first-child {
  padding: 25px 25px 0px 25px !important;
}

details > button:last-child {
  margin-bottom: 25px !important;
  margin-left: 25px !important;
}

.checkbox {
  opacity: 0;
  position: absolute;
}

.checkbox-label {
  background-color: #111;
  width: 50px;
  height: 26px;
  border-radius: 50px;
  position: relative;
  padding: 5px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.checkbox-label .ball {
  background-color: #fff;
  width: 22px;
  height: 22px;
  position: absolute;
  left: 2px;
  top: 2px;
  border-radius: 50%;
  transition: transform 0.2s linear;
}

.checkbox:checked + .checkbox-label .ball {
  transform: translateX(24px);
}

.dark-mode-toggle input[type='checkbox'] {
  position: absolute !important;
}

.dark-mode-toggle .MuiSwitch-thumb {
  background-color: var(--sidebar-bg-color) !important;
}

.dark-mode .dark-mode-toggle .MuiSwitch-track {
  background-color: var(--table-text-color) !important;
}

.subscription-line {
  background: #222;
}

.adminsearch {
  background-color: rgba(var(--primary-bg-color-rgb), 70%);
}

.dark-mode .subscription-line {
  background-color: var(--secondary-bg-color);
  border-color: var(--border-color) !important;
}

.metaFieldgroup > ul {
  padding: 10px;
  background-color: var(--secondary-bg-color);
  border-radius: 4px;
}

.metaFieldgroup > ul > li {
  padding: 7px 15px;
  font-size: 15px;
  text-transform: capitalize;
  width: 100%;
  border-radius: 4px;
  text-align: center;
}

.metaFieldgroup > ul > li:hover {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color) !important;
  cursor: pointer;
}

.selected-brand {
  border: 2px solid #2c6ecb;
}

.hover-border:hover {
  border: 2px solid #3b82f6;
}

.bg-gray-100 {
  background-color: #f0f0f0;
}

.default-border {
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.selected-border {
  border: 2px solid #3b82f6;
  /* Color for selected section */
}

.page_builder_container {
  border: 2px solid transparent;
  /* Default transparent border */
}

.page_builder_container:hover {
  border: 2px solid transparent;
  /* Transparent border on hover */
}

.page_builder_container.selected {
  border: 2px solid #2c6ecb;
  /* Blue border for selected section */
}

.hover-border {
  border: 2px solid transparent;
  /* Default border is transparent */
  transition: border 0.2s ease-in-out;
}

.hover-border:hover {
  border: 2px solid #3b82f6;
  /* Border color on hover */
}

.product-name-div {
  border: 2px solid transparent;
  transition: border 0.3s ease;
}

.product-name-div:hover {
  border: 2px solid #3b82f6;
}

.active-border {
  border: 2px solid #3b82f6;
}

/* .hover-border {
    transition: border 0.3s ease;
} */

.hover-border:hover {
  border: 2px solid #3b82f6;
}

.parent-hover:hover .child-hover {
  background-color: #6f7a86;
}

.element-title {
  font-size: 12px;
}

.metaFieldgroupCustome > ul {
  padding: 10px;
  background-color: var(--secondary-bg-color);
  border-radius: 4px;
}

.metaFieldgroupCustome > ul > li {
  padding: 7px 15px;
  font-size: 15px;
  text-transform: capitalize;
  width: 100%;
  border-radius: 4px;
  display: flex;
}

.metaFieldgroupCustome > ul > li:hover {
  background-color: var(--primary-bg-color);
  color: var(--primary-text-color) !important;
  cursor: pointer;
}

.bg-radio-button input {
  font-size: 15px !important;
}
