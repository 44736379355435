/* *{
    line-height: 120%;
} */
.edit-style-section .edit-select{
    padding: 2.9px 15px !important;
    font-size: 12px !important;
    border: none;
    border-radius: 4px;
}
.edit-style-section .edit-input{
    padding: 2.9px 15px !important;
    font-size: 12px !important;
    background-color: transparent !important;
}
.edit-style-section .edit-border{
    border-color: #D9D9D9;
}
.edit-style-section .unit-select{
    position: absolute;
    right: 15px;
    font-size: 12px !important;
    height: 80%;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    text-transform: uppercase;
    padding: 0 10px !important;
}
.edit-style-section .size-box .unit-select{
    right: 2px !important;
    padding: 0 5px !important;
}
.edit-style-section .unit-input{
    border-radius: 0 !important;
    padding: 0 !important;
    font-size: 12px !important;
    width: 100% !important;
    height: 28px !important;
    text-align: center;
    display: inline-block;
}
.hovered .path1,
.hovered .path2 {
  stroke: #ffffff;
}

.hover\:text-white:hover .path1,
.hover\:text-white:hover .path2 {
  stroke: #ffffff;
}

.active .path1,
.active .path2 {
  stroke: #ffffff;
}