@tailwind base;
@tailwind components;
@tailwind utilities;
@import './ShopeasyUiKit.css';
@import './Media.css';

* {
  font-family: "Inter", sans-serif !important;
}


ql-font-serif {
  font-family: 'Instrument Serif', serif !important;
}

ql-font-monospace {
  font-family: 'Space Mono', monospace !important
}


.loader {
  width: 70px;
  padding: 5px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: var(--btn-bg-primary);
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

.menu-dropdown {
  transition: all 0.5s;
}

.menu-dropdown .close {
  opacity: 0;
  visibility: hidden;
  height: 0;
  transition: all 0.5s;
  margin-left: 20px;
}

.bg {
  background-color: #74EBD5;
  background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);
}

.bg .form {
  box-shadow: 0 20px 45px 0 rgb(0, 0, 0, 0.2);
}

.menu-dropdown .open {
  /* opacity: 1;
  visibility: visible; */
  /* height: 100%; */
  transition: all 0.5s;
  margin-left: 20px;
  animation: menu 5s 1;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

.ql-snow .ql-editor h1 {
  font-size: 40px !important;
}

.ql-snow .ql-editor h2 {
  font-size: 36px !important;
}

.ql-snow .ql-editor h3 {
  font-size: 32px !important;
}

.ql-snow .ql-editor h4 {
  font-size: 28px !important;
}

.ql-snow .ql-editor h5 {
  font-size: 24px !important;
}

.ql-snow .ql-editor h6 {
  font-size: 20px !important;
}

@layer base {

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

:root {
  --sidebar-bg-color: #28282B;
  --sidebar-bg-color-rgb: 40, 40, 43;
  --btn-bg-primary: #28282B;
  --btn-bg-primary-rgb: rgb(40, 40, 43);
  --bg-btn-hover: #000000;
  --bg-btn-hover-rgb: rgb(0, 0, 0);
  --primary-bg-color: #F0F0F0;
  --primary-bg-color-rgb: 245, 245, 245;
  --secondary-bg-color: #fff;
  --secondary-bg-color-rgb: rgb(255, 255, 255);
  --primary-text-color: #28282B;
  --primary-text-color-rgb: rgb(40, 40, 43);
  --border-color: #e5e5e5;
  --border-color-rgb: 229, 229, 229;
  --focus-border-color: #a3a3a6;
  --focus-border-color-rgb: rgb(163, 163, 166);
  --focus-tab-color: #ececec;
  --focus-tab-color-rgb: rgb(236, 236, 236);
  --table-text-color: #000000;
  --table-text-color-rgb: rgb(102, 107, 120);
  --tawk-header-background-color: var(--sidebar-bg-color);
  --tawk-header-background-color-rgb: var(var(--sidebar-bg-color-rgb))
}

.dark-mode {
  --sidebar-bg-color: #111;
  --sidebar-bg-color-rgb: rgb(17, 17, 17);
  --primary-bg-color: #222;
  --primary-bg-color-rgb: rgb(34, 34, 34);
  --secondary-bg-color: #333333;
  --secondary-bg-color-rgb: rgb(51, 51, 51);
  --primary-text-color: #ccc;
  --primary-text-color-rgb: rgb(204, 204, 204);
  --border-color: #444;
  --border-color-rgb: rgb(68, 68, 68);
  --table-text-color: #a0a0a0;
  --table-text-color-rgb: rgb(160, 160, 160);
  --focus-border-color: #555;
  --focus-border-color-rgb: rgb(85, 85, 85);
  --focus-tab-color: #444;
  --focus-tab-color-rgb: rgb(68, 68, 68);
}

body {
  color: var(--primary-text-color);
}

.plan-popup .MuiBackdrop-root.MuiModal-backdrop {
  background-color: initial !important;
}

.primary-bg-color {
  background-color: var(--primary-bg-color) !important;
}

.secondary-bg-color,
.cTsUPQ {
  background-color: var(--secondary-bg-color) !important;
}

.MuiLinearProgress-root {
  background-color: var(--sidebar-bg-color) !important;
  height: 3px !important;
}

.MuiLinearProgress-bar {
  background-color: white !important;
  transition: transform 2s linear !important;
  width: 80% !important;
  right: 0 !important;
}

.text-btn {
  color: var(--btn-bg-primary);
}

.table-text {
  color: var(--table-text-color) !important;
}

.bg-btn {
  background-color: var(--btn-bg-primary);
  border: 1px solid var(--btn-bg-primary);
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 100%;
}

.sidebar ::-webkit-scrollbar {
  width: 5px;
}

.sidebar ::-webkit-scrollbar-thumb {
  background-color: #3d3d40 !important;
}

.dark-mode .sidebar ::-webkit-scrollbar-thumb {
  background-color: var(--primary-bg-color) !important;
}

.sidebar ::-webkit-scrollbar-track {
  background-color: var(--btn-bg-primary) !important;
}

.dark-mode .sidebar ::-webkit-scrollbar-track {
  background-color: var(--sidebar-bg-color) !important;
}

.sidebar {
  transition: all 0.3s;
  min-width: 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  flex-grow: 0;
  flex-shrink: 1;
  min-height: 1px;
}
.sidebar button:focus,.sidebar a:focus{
  outline: none;
}

.MuiBadge-badge {
  background-color: var(--sidebar-bg-color) !important;
  color: var(--primary-bg-color) !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  top: -1px !important;
  right: -2px !important;
}
.dark-mode .MuiBadge-badge{
  color: var(--table-text-color) !important;
}
.notification .MuiPaper-root.MuiPaper-elevation {
  border-radius: 4px 0 0 4px;
}

.my-tooltip-class.MuiTooltip-tooltip {
  background-color: var(--sidebar-bg-color) !important;
  padding: 8px 16px !important;
  color: white !important;
  margin-left: 20px !important;
  font-size: 13px !important;
  letter-spacing: 1px;
}

.my-tooltip-class .MuiTooltip-arrow::before {
  background: var(--sidebar-bg-color) !important;
}

.sidebar.close {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.close .sidebar {
  width: 60px;
  min-width: 60px;
  max-width: 60px;
}

.absolute-loader {
  width: 100%;
  height: 100%;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%); */
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--secondary-bg-color) !important;
  border-radius: 50%;
}

.sidebar::-webkit-scrollbar-track {
  /* background-color: #fff; */
  background: var(--sidebar-bg-color) !important;
}

.sidebar::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  background-color: var(--primary-bg-color) !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  --tw-bg-opacity: 1;
  /* background-color: rgb(209 213 219 / var(--tw-bg-opacity)); */
  background-color: var(--primary-bg-color);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  --tw-border-opacity: 1;
  background-color: var(--table-text-color);
  /* background-color: rgb(156 163 175 / var(--tw-border-opacity)); */
  border-color: var(--border-color);
}

.tooltip-perent {
  position: relative;
  display: block;
  transition: all 0.3s;
}

.tooltip {
  position: absolute;
  left: -70%;
  top: 130%;
  opacity: 0;
  transition: all 0.3s;
}

.popup-content .MuiPaper-root.MuiPopover-paper {
  position: absolute !important;
  z-index: 20 !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  border-radius: 4px !important;
}

.tooltip-arrow {
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  background-color: var(--primary-text-color);
  width: 10px;
  height: 6px;
  position: absolute;
  top: -2px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.tooltip-perent:hover .tooltip {
  opacity: 1;
}

.nb-spinner,
.nb-spinner-small {
  display: inline-block;
  width: 30px;
  height: 30px;
  border: 4px solid var(--primary-text-color);
  border-top: 4px solid var(--border-color);
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.nb-spinner-small {
  width: 20px;
  height: 20px;
  border: 2px solid var(--primary-text-color);
  border-top: 2px solid var(--border-color);
}

.data-loader {
  display: inline-block;
  width: 50px;
  height: 50px;
  border: 4px solid var(--border-color);
  border-top: 4px solid var(--sidebar-bg-color);
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.custom-toast {
  font-size: 10px !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pagination {
  background-color: var(--secondary-bg-color);
  border-color: var(--border-color) !important;
  color: var(--table-text-color);
}

.pagination .MuiButtonBase-root.MuiPaginationItem-root.Mui-selected {
  background-color: var(--primary-bg-color);
  border-radius: 100%;
}

.pagination .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text {
  color: var(--table-text-color);
}

.pagination .MuiButtonBase-root.MuiPaginationItem-root.MuiPaginationItem-text:hover {
  background-color: var(--primary-bg-color) !important;
}

.pagination .selected {
  border: none !important;
}

.active {
  color: white !important;
}

.detail-container {
  padding: 0 !important;
}

.text-primary {
  color: var(--primary-text-color) !important;
}

.text-link {
  color: var(--btn-bg-primary) !important;
  transition: all 0.3s;
}

.text-link:hover {
  color: var(--btn-bg-primary) !important;
}

.page_builder_container:hover {
  border: 2px solid var(--btn-bg-primary);
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-radius: 0px 0px 4px 4px !important;
}

.ql-toolbar.ql-snow {
  border-radius: 4px 4px 0px 0px !important
}

.bg-primary {
  background-color: var(--sidebar-bg-color) !important;
}

.btn {
  background-color: var(--btn-bg-primary) !important;
  border-radius: 4px !important;
  padding: 8px 20px !important;
  border: 1px solid var(--btn-bg-primary);
}

.secondary-btn {
  border: 1px solid var(--btn-bg-primary);
  background-color: white;
  color: var(--btn-bg-primary);
  transition: all 0.3s;
  border-radius: 4px !important;
  padding: 8px 20px !important;
}

.ql-editor {
  min-height: 150px !important;
  max-height: auto;
}

.ql-editor.elementor-editor {
  min-height: unset !important;
}

.main-border-color {
  border-color: var(--border-color) !important;
}

.focus-border-color {
  border-color: var(--focus-border-color) !important;
}

.focus-tab {
  background-color: var(--focus-tab-color) !important;
  color: var(--primary-text-color) !important;
}


.tab {
  padding: 8px 15px;
  background-color: transparent;
  font-size: 12px;
  line-height: 13px;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: 500;
  color: var(--table-text-color);
  transition: all 0.2s;
  display: inline-block;
}
.file-tag-hover .tab{
  padding: 8px 10px !important;
  border-radius: 30px !important;
}

.tab:hover {
  color: var(--primary-text-color);
}

.border-secondary {
  border: 1px solid var(--btn-bg-primary);
}

.react-datepicker__header {
  background-color: var(--primary-bg-color) !important;
  border-bottom: 1px solid var(--border-color) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--primary-text-color) !important;
}

.react-datepicker__month {
  margin: 0 !important;
  padding: 0.4rem !important;
  background-color: var(--secondary-bg-color) !important;
}

.react-datepicker {
  border-color: var(--border-color) !important;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  border-bottom-color: var(--border-color) !important;
}

.react-datepicker__day.react-datepicker__day--disabled {
  opacity: 60% !important;
  cursor: auto;
}

.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__day:hover,
.react-datepicker__day--in-range {
  background-color: var(--primary-bg-color) !important;
  color: var(--primary-text-color) !important;
}


.react-datepicker-popper {
  z-index: 35 !important;
}

.secondary-btn:hover {
  background-color: var(--btn-bg-primary);
  color: white;
}

.btn:hover {
  background-color: var(--bg-btn-hover) !important;
}

.border-primary {
  border-color: var(--sidebar-bg-color);
}

.border-primary-color {
  border-color: var(--btn-bg-primary) !important;
}

.info-link {
  color: #7f7f7f;
  transition: all 0.3s;
}

.info-link:hover {
  color: #616161;
}

.fwGovb {
  font-weight: 700 !important;
  text-transform: uppercase;
}



.rdt_TableCell {
  color: var(--table-text-color);
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
}

.gVZIpW,
.dMnwYK:nth-of-type(n) {
  height: 55px !important;
}

.variant-table .gVZIpW,
.variant-table .dMnwYK:nth-of-type(n),
.delete-logs .gVZIpW,
.delete-logs .dMnwYK:nth-of-type(n) {
  min-height: 55px !important;
  height: auto !important;
  padding: 5px 0 !important;
}

.sticky-column {
  position: sticky !important;
  left: 0 !important;
  background-color: inherit !important;
  z-index: 5 !important;
}

.gVZIpW:not(:last-of-type) {
  border-bottom: 1px solid rgba(var(--border-color-rgb),70%) !important;
  /* border-top: 0.5px solid rgba(var(--border-color-rgb),70%) !important; */
}
.dark-mode .gVZIpW:not(:last-of-type){
  border-bottom: 1px solid var(--border-color) !important;
}
.dashboard .gVZIpW,
thead tr,
tfoot tr {
  height: 45px !important;
  color: var(--table-text-color) !important;
  font-size: 12px !important;
  background-color: var(--secondary-bg-color) !important;
}

.gVZIpW {
  background-color: var(--secondary-bg-color) !important;
}

.cTsUPQ {
  color: var(--table-text-color) !important;
  min-height: 280px !important;
}

.rdt_TableHead, thead {
  background-color: #a6a6ab !important;
}
.dark-mode .rdt_TableHead,.dark-mode thead{
  background-color: var(--primary-bg-color) !important;
}

.rdt_TableRow {
  cursor: pointer;
}

thead tr,
thead td,
thead div,
thead span,
thead div button {
  background-color: transparent !important;
}

thead tr,
tfoot tr {
  text-transform: uppercase !important;
}

tbody tr {
  color: var(--primary-text-color) !important;
  font-size: 13px !important;
  height: 53px !important;
  /* display: flex; */
  width: 100%;
  align-items: center;
}

.variant-table tbody tr {
  height: auto !important;
  min-height: 53px !important;
}

tbody tr:hover {
  background-color: var(--primary-bg-color) !important;
}

/* .gVZIpW {
  border-top: 0.5px solid var(--border-color) !important;
} */

.gVZIpW:hover {
  background-color: rgba(var(--primary-bg-color-rgb),70%) !important;
  /* border-top: 0.5px solid var(--border-color) !important; */
  border-color: rgba(var(--border-color-rgb),70%);
  outline: none !important;
}
.dark-mode .gVZIpW:hover{
  background-color: var(--btn-bg-primary) !important;
}
.drag-btn {
  opacity: 0;
}

.gVZIpW:hover .drag-btn {
  opacity: 1;
}

.cRzJdb,
.sc-kpDqfm {
  margin-left: 10px !important;
  font-size: 8px;
}

.dIyxTF,
.jDsDCL {
  min-width: 30px !important;
  flex: 0 0 0px !important;
  justify-content: end !important;
}

/* .MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 5px !important;
}

.MuiAutocomplete-hasPopupIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root,
.MuiAutocomplete-hasClearIcon.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px 4px !important;
} */

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10.5px 14px !important;
}

.faq-autocomplete.css-1h51icj-MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-input {
  color: black !important;
}

.MuiInputBase-root.Mui-focused {
  border: none !important;
  outline: none !important;
}

.myDIV {
  background-color: black;
}

.apexcharts-reset-icon,
.apexcharts-pan-icon {
  display: none;
}

.apexcharts-toolbar {
  display: none !important;
}

.breadcrumb-divider {
  position: relative;
  margin-right: 18px;
  display: inline-block;
}

.breadcrumb-divider::after {
  content: '';
  width: 9px;
  height: 100%;
  border-top: 1px solid var(--border-color);
  transform: rotate(-70deg);
  position: absolute;
  right: -20px;
  top: 4px;
}

.card {
  position: relative;
  display: flex;
  width: 450px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background: linear-gradient(rgba(255, 255, 255, 0.12) 0%, var(--secondary-bg-color) 100%);
  background-clip: border-box;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  -webkit-box-shadow: 0px 0px 5px 0px rgb(249, 249, 250);
  -moz-box-shadow: 0px 0px 5px 0px rgba(212, 182, 212, 1);
  box-shadow: 0px 0px 5px 0px rgb(161, 163, 164)
}

.card .card-body {
  padding: 1rem 1rem
}

.card-body {
  flex: 1 1 auto;
  padding: 1.25rem
}

p {
  font-size: 14px
}


.cross {
  padding: 10px;
  color: #d6312d;
  cursor: pointer
}

.continue:focus {
  outline: none
}

.continue {
  border-radius: 4px;
  text-transform: capitalize;
  font-size: 13px;
  padding: 8px 19px;
  cursor: pointer;
  color: var(--primary-bg-color);
  background-color: #D50000
}

.continue:hover {
  background-color: #D32F2F !important
}

.cart-items .css-3bmhjh-MuiPaper-root-MuiPopover-paper {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12)
}

.react-colorful,
.w-color-swatch.w-color-github.w-color-chrome {
  position: absolute !important;
  left: 0;
  display: block;
  top: 100%;
  z-index: 999999 !important;
}

.kubBPN:hover {
  background-color: transparent !important;
  color: black !important;
}

.kubBPN:focus {
  background-color: transparent !important;
}

@media (max-width: 767px) {

  .btn,
  .bulk-btn,
  .secondary-btn {
    border-radius: 4px !important;
    padding: 6px 10px !important;
    font-size: 12px !important;
  }

  .back-arrow {
    display: none;
  }

  .filter-btn {
    width: 35px;
    height: 35px;
  }

  /* input[type="text"],
  input[type="number"],
  input[type="Number"],
  input[type="email"],
  input[type="password"],
  input[type="date"],
  select,
  textarea {
    padding: 6px 12px !important;
    font-size: 14px !important;
    line-height: 20px !important;
  } */

  .sticky-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
  .sticky-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(3),
  .customer-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(3),
  .customer-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(3),
  .category-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-child(2),
  .category-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-child(2),
  .add-variant-table table thead tr td:first-child,
  .add-variant-table table tbody tr td:first-child {
    position: relative !important;
  }

  .sticky-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child,
  .sticky-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child,
  .product-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child,
  .product-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child,
  .category-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child,
  .category-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child,
  .blog-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child,
  .blog-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child,
  .customer-table .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:nth-last-child(2),
  .customer-table .rdt_Table .rdt_TableBody .rdt_TableRow .rdt_TableCell:nth-last-child(2),
  .add-discount-table table thead tr td:last-child,
  .add-discount-table table tbody tr td:last-child {
    position: relative !important;
  }
}

.slick-next,
.slick-prev {
  /* background: black !important;
  opacity: 75%; */
  color: white !important;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
  z-index: 9 !important;
  display: inline-flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.slick-next {
  right: -36px !important;
}

.slick-prev {
  left: -36px !important;
}

.main-slider .slick-prev {
  left: 36px !important;
}

.main-slider .slick-next {
  right: 36px !important;
}

.slick-prev:before,
.slick-next:before {
  content: '' !important;
}

.custom-prev-arrow,
.custom-next-arrow {
  /* Your custom styles for arrows */
  background-color: blue;
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}

.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: white !important;
}

input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid black;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
  position: relative;
}

input[type="radio"]:checked::before {
  content: '';
  display: block;
  width: 12px;
  height: 12px;
  background-color: black;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  left: 2px;
}

.css-j204z7-MuiFormControlLabel-root.Mui-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.css-143ulsi-JoySnackbar-endDecorator button {
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.css-143ulsi-JoySnackbar-endDecorator button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(1.1);
}

.bold-date {
  font-weight: bold;
}

.order-table .cTsUPQ {
  min-height: 72px !important;
}

.dragLine {
  border:none !important;
}

@keyframes slideIn {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.animate-slideIn {
  animation: slideIn 0.5s ease-out forwards;
}

.marquee-wrapper {
  width: 100%;
  overflow: hidden;
}

.marquee-text {
  display: flex;
  animation: marqueeLeftToRight 10s linear infinite;
}

.marquee-text p {
  white-space: nowrap;
  color: #222;
  font-size: 15px;
  padding: 5px;
}

@keyframes marqueeLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}