@media (min-width: 1400px) {
    .container-custom {
        padding-left: 15px;
        padding-right: 15px;
    }

    .productHover .image-div:hover .additional-image {
        left: 0;
    }

    .productHover .image-div:hover .outofstock {
        left: 50%;
    }

    .productHover .image-div:hover .addtocart {
        bottom: 0;
    }

    .productHover .image-div:hover .bedge {
        left: 10px;
    }

    .productHover .image-div:hover .buttons {
        right: 0;
    }

    .productHover .image-div:hover .counter {
        left: 50%;
    }

  

    h1 {
        font-size: 40px !important;
        line-height: 45px !important;
    }

    h2 {
        font-size: 36px !important;
        line-height: 41px !important;
    }

    h3 {
        font-size: 32px !important;
        line-height: 37px !important;
    }

    h4 {
        font-size: 26px !important;
        line-height: 33px !important;
    }

    h5 {
        font-size: 22px !important;
        line-height: 29px !important;
    }

    h6 {
        font-size: 18px !important;
        line-height: 25px !important;
    }

  
}

@media (max-width: 1399px) {
    .productHover .outofstock {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: ease 0.4s;
    }

    /* 
    .main-slider .swiper-button-next {
        right: 80px;
        left: auto;
    }

    .main-slider .swiper-button-prev {
        left: 80px;
        right: auto;
    }

    .main-slider .swiper-button-next:after,
    .main-slider .swiper-button-prev:after {
        font-size: 30px;
        opacity: 0;
        transition: all 0.6s;
    }

    .main-slider:hover .swiper-button-next:after,
    .main-slider:hover .swiper-button-prev:after {
        opacity: 1;
    } */

    h1 {
        font-size: 40px !important;
        line-height: 45px !important;
    }

    h2 {
        font-size: 36px !important;
        line-height: 41px !important;
    }

    h3 {
        font-size: 32px !important;
        line-height: 37px !important;
    }

    h4 {
        font-size: 28px !important;
        line-height: 33px !important;
    }

    h5 {
        font-size: 24px !important;
        line-height: 29px !important;
    }

    h6 {
        font-size: 20px !important;
        line-height: 25px !important;
    }
}

@media (max-width: 1199px) {
    .productHover .outofstock {
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: capitalize;
        position: absolute;
        text-align: center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: ease 0.4s;
    }

    /* .main-slider .swiper-button-next {
        right: 80px;
        left: auto;
    }

    .main-slider .swiper-button-prev {
        left: 80px;
        right: auto;
    }

    .main-slider .swiper-button-next:after,
    .main-slider .swiper-button-prev:after {
        font-size: 30px;
        opacity: 0;
        margin: 0;
        transition: all 0.6s;
    }

    .-slider .swiper-button-next:after,
    .main-slider .swiper-button-prev:after {
        font-size: 30px;
        opacity: 0;
        margin: 0;
        transition: all 0.6s;
    }

    .main-slider:hover .swiper-button-next:after,
    .main-slider:hover .swiper-button-prev:after {
        opacity: 1;
    } */
}

@media (max-width: 991px) {
    .productHover .image-div {
        left: 0 !important;
    }

    /* .main-slider .swiper-button-next {
        right: 80px;
        left: auto;
    }

    .main-slider .swiper-button-prev {
        left: 80px;
        right: auto;
    }

    .main-slider .swiper-button-next:after,
    .main-slider .swiper-button-prev:after {
        opacity: 1;
    } */

    h1 {
        font-size: 38px !important;
        line-height: 43px !important;
    }

    h2 {
        font-size: 34px !important;
        line-height: 37px !important;
    }

    h3 {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    h4 {
        font-size: 26px !important;
        line-height: 31px !important;
    }

    h5 {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    h6 {
        font-size: 18px !important;
        line-height: 23px !important;
    }
}

@media (max-width: 767px) {
    /* .main-slider .swiper-button-next {
        right: 30px;
        left: auto;
    }

    .main-slider .swiper-button-prev {
        left: 30px;
        right: auto;
    } */


    h1 {
        font-size: 32px !important;
        line-height: 43px !important;
    }

    h2 {
        font-size: 34px !important;
        line-height: 37px !important;
    }

    h3 {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    h4 {
        font-size: 26px !important;
        line-height: 31px !important;
    }

    h5 {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    h6 {
        font-size: 16px !important;
        line-height: 23px !important;
    }

    .category-slider .swiper-wrapper {
        height: 260px !important;
    }
}

@media (max-width: 575px) {
    .container-custom {
        /* width: 100% !important; */
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
        margin-right: auto;
        margin-left: auto;
    }

    .bottom-nav {
        display: flex;
    }

    .fixed-atc {
        bottom: 40px;
        padding: 0 70px;
    }

    .chat {
        left: 20px;
        bottom: 60px;
    }

    .backtotop {
        bottom: 60px;
        right: 20px;
    }

    .footer {
        margin-bottom: 40px;
    }

    /* .main-slider .swiper-button-next {
        right: 0px;
        left: auto;
    }

    .main-slider .swiper-button-prev {
        left: 0px;
        right: auto;
    } */
    details>div:last-child {
        padding-bottom: 16px !important;
    }
    
    details>button:last-child {
        margin-bottom: 16px !important;
        margin-left: 16px !important;
    }
    details>div {
        padding: 0px 16px 0px 16px !important;
    }
    details summary {
        padding: 16px !important;
        position: relative;
    }
    h1 {
        font-size: 32px !important;
        line-height: 43px !important;
    }

    h2 {
        font-size: 34px !important;
        line-height: 37px !important;
    }

    h3 {
        font-size: 30px !important;
        line-height: 35px !important;
    }

    h4 {
        font-size: 26px !important;
        line-height: 31px !important;
    }

    h5 {
        font-size: 22px !important;
        line-height: 28px !important;
    }

    h6 {
        font-size: 16px !important;
        line-height: 23px !important;
    }
}